$(document).ready(function(){
    /*
     * Right mobile nav
     */
    var rightMenu = document.getElementById('menu-right');

    document.querySelector('.navbar-toggle--right').addEventListener('click', function() {
        slideoutRight.toggle();

        rightMenu.style.zIndex = '0';
        $(this).addClass('active');

        slideoutRight.on('close', function () {
            rightMenu.style.zIndex = '-1';
            $('.navbar-toggle--right').removeClass('active');

        });
    });

    var slideoutRight = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu-right'),
        'padding': 188,
        'tolerance': 70,
        'side': 'right',
        'touch': false
    });

    //on slideout without toggle button
    slideoutRight.on('beforeopen', function () {
        rightMenu.style.zIndex = '0';
    });

    //touch option onlye enabled für closing (touch opening conflicts with slick-slider)
    slideoutRight.once('open', slideoutRight._initTouchEvents);
    slideoutRight.on('open', slideoutRight.enableTouch);
    slideoutRight.on('close', slideoutRight.disableTouch);

    /*
     * Mobile nav targeting
     */
    $('.mobile-nav').find('.dropdown > a').on('click', function(event) {

        event.preventDefault();

        if($(this).parent().hasClass('open')) {
            location.assign($(this).attr('href'));
        } else {
            $('.mobile-nav').find('.dropdown').removeClass('open');
            $(this).parent().addClass('open');
        }

    });

    /*
     * Slick slider
     */
    $('.slider').each(function() {
        $(this).slick({
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            infinite: true,
            slidesToScroll: 1,
            swipe: true,
            appendDots: $(this).siblings('carousel-textlayer').children('carousel-indicators')
        });

        console.log($(this));
        console.log($(this).siblings('carousel-textlayer').children('carousel-indicators'));
        console.log($(this).next('carousel-textlayer'));

    });

    /*
     * Testimonials Slider
     */
    $('.testimonials').slick({
        dots: false,
        arrows: false,
        autoplay: true,
        autoPlaySpeed: 4000,
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        swipe: true
    });


    // Unite Gallery initialisieren
    if ($("#unitegallery-boxed").length) {
        $("#unitegallery-boxed").unitegallery({
            tiles_type: "justified",
            tiles_justified_space_between: 30
        });
    }


    // Unite Gallery initialisieren
    if ($("#unitegallery-wide").length) {
        $("#unitegallery-wide").unitegallery({
            tiles_justified_row_height: 200,
            tiles_type: "justified",
            tiles_justified_space_between: 0
        });
    }


    /*
     * Fancybox
     */
    $(".fancybox").fancybox();

    $('.navbar-search').click(function() {
        $(this).toggleClass('open');
        $('.navbar-form').slideToggle();
    });

    $('.navbar-nav .dropdown > a').on('click', function() {
        if($(this).parent().hasClass('open')) {
            window.location.href = $(this)[0].baseURI + '' + $(this).attr('href');
        }
    });

    var highest_element = 0;
    var apDetailListBox = $('.ap-detail-list-box');
    apDetailListBox.each(function() {
        if ($(this).height() > highest_element) {
            highest_element = $(this).height();
        }
    });
    apDetailListBox.each(function() {
        $(this).height(highest_element);
    });


});
